<template>
  <div class="filters" ref="filtersWrapper" >
    <a-popover placement="bottom" :getPopupContainer="()=>$refs.filtersWrapper" 
      v-model:open="visible"
      @openChange="handleOpen"
      trigger="click"
    >
     <artmisads-select-button
        showFilterIcon="true"
        :filterNum="filtersNum"
        showDownIcon="true"
      >Filters
      </artmisads-select-button>
     <template #content  >
        <div class="filters-down" ref="filtersDown">
          <div class="filters-name">Commission Min</div>
          <div class="commission-number">
              <artmisads-input-number
              v-model:value="filters.commission"
              style="width: 190px"  
              placeholder="Please enter..."
              :step="0.1"
              min="0"
              max="100"
              suffix="%"
              @change="handleChange($event,'comission')"
              >
              </artmisads-input-number>
            </div>
          <div class="filters-name">Availability </div>
          <div >
            <artmisads-select
              v-model:value="filters.availability"
              style="width: 190px"
              @change="handleChange($event,'availability')"
              :getPopupContainer="()=>$refs.filtersDown"
            >
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="IN_STOCK" >In Stock</a-select-option>
              <a-select-option value="OUT_OF_STOCK">Out of Stock</a-select-option>
            </artmisads-select>
          </div>
          <div class="filters-name" >Price </div>
          <div>
            <artmisads-select
              v-model:value="filters.price"
              style="width: 190px"
              @change="handleChange($event,'price')"
              :getPopupContainer="()=>$refs.filtersDown"
              placement="topRight"
            >
              <a-select-option value="all"  >All</a-select-option>
              <a-select-option value="get10" >Under $10</a-select-option>
              <a-select-option value="get15" >$10 to $25</a-select-option>
              <a-select-option value="get25" >$25 to $50</a-select-option>
              <a-select-option value="get50" >$50 to $100</a-select-option>
              <a-select-option value="get100" >$100 to $200</a-select-option>
              <a-select-option value="get200" >$200 & Above </a-select-option>
              
            </artmisads-select>
          </div>
          <div class="save-btn">
            <artmisads-button type="primary" size="small" @click="onSave">Save</artmisads-button>
          </div>
        </div>
        <div class="reset">
          <a-button @click="reset">
              <span style="margin-right: 3px;">Reset</span><span v-show="filtersNum" >{{ filtersNum }}</span><span style="margin-left: 3px;">Filters</span> 
          </a-button>
        </div>
     </template> 
    </a-popover>
   
  </div>
  
</template>
<script setup name="FilterOptions">
import {ref,onMounted, reactive, toRefs, toRaw} from 'vue';
import { UpOutlined,DownOutlined } from '@ant-design/icons-vue';
const emit =defineEmits(['get-data']);
let visible=ref(false);
let filtersWrapper =ref();
let filtersDown =ref();
let filtersNum =ref(0);
let filters=reactive({
  availability:'all',
  commission:undefined,
  price:'all'
})
let filtersOrigi={
  availability:'all',
  commission:null,
  price:'all'
}
let options =[];
const prices=[
  {
    lable:'get10',
    maxPrice:10,
    minPrice:0
  },
  {
    lable:'get15',
    maxPrice:25,
    minPrice:10
  },
  {
    lable:'get25',
    maxPrice:50,
    minPrice:25
  },
  {
    lable:'get50',
    maxPrice:100,
    minPrice:50
  },
  {
    lable:'get100',
    maxPrice:200,
    minPrice:100
  },
  {
    lable:'get200',
    maxPrice:null,
    minPrice:200
  },

]
const onSave=()=>{
  visible.value=false
  let req={...filters};
  if (req.price !='all'){
    prices.forEach(v=>{
      if(v.lable==req.price){
        req.maxPrice = v.maxPrice;
        req.minPrice =v.minPrice;
      }
    })
    req.page=1;
  } 
  delete req.price;
  emit('get-data',req)
}
const handleChange =(e,itemName)=>{
  let isMark = options.includes(itemName)
  if(!isMark &&  e!= filtersOrigi[itemName]){
    options.push(itemName);
    filtersNum.value ++
  }
  if(isMark &&  e == filtersOrigi[itemName]){
    let index=options.findIndex(item=>item==itemName);
    options.splice(index,1);
    if(filtersNum){ filtersNum.value --}
   
  }
  if(itemName == 'comission'){
    filters.commission=e;
    if (isNaN(parseFloat(e))) {
    filters.commission = undefined;
    return;
    }
    filters.commission = Math.floor(e * 10) / 10;
  }
  // let req={...filters};
  // if (req.price !='all'){
  //   prices.forEach(v=>{
  //     if(v.lable==req.price){
  //       req.maxPrice = v.maxPrice;
  //       req.minPrice =v.minPrice;
  //     }
  //   })
  //   req.page=1;
  // } 
  // delete req.price;
  // emit('get-data',req)
}
const reset=()=>{
  visible.value=false
  let _filters={
    availability:'all',
    commission:undefined,
    price:'all'
  }
  options=[];
  Object.assign(filters,_filters);
  filtersNum.value=0;
  emit('get-data',{})
}
</script>
<style lang="less" scoped>
@base-font:{
  font-family: Sora-Regular;
  font-size: 14px;
  color: var(--dark-1);
  letter-spacing: 0.4px;
  line-height: 20px;
}
.filters-down:deep(.ant-input-number-handler-wrap){
  opacity: 1 !important;
}
.filters{
  .filters-down{
    width: 230px;
    padding: 16px 16px 0;
    border-radius: 8px;
    background-color: var(--color-white);
  }
  .filters-name{
    margin:16px 0 8px 0;
    @base-font();
    opacity: 0.7;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 18px;
  }
  .save-btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
  }
  .reset{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top:1px solid var(--border-1) ;
  }
  .filters-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @base-font();
    font-weight: 500;
    opacity: .7;
  }
  .filters-num{
    margin: 4px;
    width: 20px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--yellow-1);
    display: flex;
    align-items: center;
    justify-content: center;
    @base-font();
    font-weight: 500;
    line-height: 14px;
  }
}

</style>